/* eslint-disable @typescript-eslint/no-floating-promises */
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {errorInCheckoutSfParams} from '@wix/bi-logger-ec-sf/v2/types';
import {PaymentMethod} from '@wix/wixstores-graphql-schema-node';
import {ContinueShoppingBiOrigin, EMPTY_CART_ID, ORIGIN, PaymentMethodType} from '../../components/cart/constants';
import {CartType, ShippingRuleStatus} from '@wix/wixstores-client-core';
import {SPECS} from '../specs';
import {ICart} from '../../types/app.types';
import {
  getAdditionalFeesPrice,
  getCatalogAppIds,
  getItemTypes,
  getNumberOfAdditionalFees,
  getOriginalShippingMethod,
  getShippingMethodType,
} from '../utils/bi.utils';
import {
  clickOnShippingDestinationInCartPageSf,
  clickOnCheckoutWithEWalletSf,
  clickContinueEWalletModalSf,
  updateClickedInSelectYourShippingDestinationPopupSf,
  errorInCheckoutSf,
  cancelClickedInSelectYourShippingDestinationPopupSf,
  cartClickOnContinueShoppingSf,
  minimumOrderMessageIsShownInCart,
  clickOnAddNoteToSellerSf,
  clickOnApplyPromotionalCodeSf,
  errorWhenApplyingACouponSf,
  saveNoteToSellerSf,
  deleteNoteToSellerSf,
  viewCartPageSf,
} from '@wix/bi-logger-ec-sf/v2';

import {
  clickOnShippingDestinationInCartPageSf as clickOnShippingDestinationInCartPageSfSite,
  clickOnCheckoutWithEWallet,
  clickContinueEWalletModalSf as clickContinueEWalletModalSfSite,
  updateClickedInSelectYourShippingDestinationPopupSf as updateClickedInSelectYourShippingDestinationPopupSfSite,
  cancelClickedInSelectYourShippingDestinationPopupSf as cancelClickedInSelectYourShippingDestinationPopupSfSite,
  clickOnAddNoteToSellerSf as clickOnAddNoteToSellerSfSite,
  saveNoteToSellerSf as saveNoteToSellerSfSite,
  deleteNoteToSellerSf as deleteNoteToSellerSfSite,
  viewCartPageSf as viewCartPageSfSite,
} from '@wix/bi-logger-ec-site/v2';

type SetDestinationBIData = {
  cartId: string;
  cartType: string;
  shippingDestinationCountry?: string;
  shippingDestinationState?: string;
};

export class BIService {
  private readonly siteStore: SiteStore;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
  }

  private baseBiParams(cart: ICart): {origin: string; cartId?: string; storeId?: string} {
    return {
      origin: ORIGIN,
      cartId: !cart || cart.cartId === EMPTY_CART_ID ? undefined : cart.cartId,
      storeId: cart?.storeId,
    };
  }

  public clickOnShippingDestinationInCartPageSf(cart: ICart, cartType: string): void {
    const biParams = {
      ...this.baseBiParams(cart),
      ...BIService.setDestinationBIData(cart, cartType),
    };

    this.siteStore.experiments.enabled(SPECS.MoveCartBIEventsTo130)
      ? this.siteStore.webBiLogger.report(
          clickOnShippingDestinationInCartPageSfSite({
            ...biParams,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart.checkoutId,
          })
        )
      : this.siteStore.webBiLogger.report(clickOnShippingDestinationInCartPageSf(biParams));
  }

  public clickOnCheckoutWithEWallet(
    cart: ICart,
    cartType: string,
    paymentMethods: PaymentMethod[] = [],
    isWithOfflinePayment: boolean,
    isPickupFlow: boolean
  ): void {
    const params = {
      ...this.baseBiParams(cart),
      ...BIService.setDestinationBIData(cart, cartType),
      cartType,
      itemsCount: cart?.items.reduce((count, item) => count + item.quantity, 0) ?? 0,
      productsList: JSON.stringify(cart.items.map((item) => ({id: item.product.id, quantity: item.quantity}))),
      shippingMethodType: getShippingMethodType(isPickupFlow, cartType === CartType.DIGITAL),
      orig_shipping_method: getOriginalShippingMethod(cart),
      is_with_ewallet_payment: true,
      is_with_offline_payment: isWithOfflinePayment,
      num_of_paymet_providers: paymentMethods.length,
      paymet_providers: this.stringifyPaymentMethods(paymentMethods),
      num_of_shipping: cart.shippingRuleInfo?.shippingRule?.options?.length ?? 0,
      additionalFeesPrice: getAdditionalFeesPrice(cart),
      numberOfAdditionalFees: getNumberOfAdditionalFees(cart),
    };

    if (this.siteStore.experiments.enabled(SPECS.MoveCartBIEventsTo130)) {
      this.siteStore.webBiLogger.report(
        clickOnCheckoutWithEWallet({
          ...params,
          checkoutId: cart?.checkoutId,
          catalogAppId: getCatalogAppIds(cart),
        })
      );
    } else {
      this.siteStore.webBiLogger.report(
        clickOnCheckoutWithEWalletSf({
          ...params,
        })
      );
    }
  }

  public clickContinueEWalletModalSf(cart: ICart, cartType: string, paymentMethods: PaymentMethod[] = []): void {
    const biParams = {
      ...this.baseBiParams(cart),
      cartType,
      paymet_providers: this.stringifyPaymentMethods(paymentMethods),
      num_of_paymet_providers: paymentMethods.length,
      num_of_shipping: cart.shippingRuleInfo?.shippingRule?.options?.length ?? 0,
      itemsCount: cart?.items.reduce((count, item) => count + item.quantity, 0) ?? 0,
      productsList: JSON.stringify(cart.items.map((item) => ({id: item.product.id, quantity: item.quantity}))),
    };
    this.siteStore.experiments.enabled(SPECS.MoveCartBIEventsTo130)
      ? this.siteStore.webBiLogger.report(
          clickContinueEWalletModalSfSite({
            ...biParams,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart?.checkoutId,
          })
        )
      : this.siteStore.webBiLogger.report(clickContinueEWalletModalSf(biParams));
  }

  private stringifyPaymentMethods(paymentMethods: PaymentMethod[]) {
    return paymentMethods.map((m) => m.name).join(',');
  }

  public updateClickedInSelectYourShippingDestinationPopupSf(
    cart: ICart,
    cartType: string,
    changedDestination: {country: string; subdivision: string; zipCode: string}
  ): void {
    const biParams = {
      ...this.baseBiParams(cart),
      shippingDestinationCountry: changedDestination.country,
      shippingDestinationState: changedDestination.subdivision,
      hasZipCode: !!changedDestination.zipCode,
      cartId: cart.cartId,
      cartType,
      additionalFeesPrice: getAdditionalFeesPrice(cart),
      numberOfAdditionalFees: getNumberOfAdditionalFees(cart),
    };

    this.siteStore.experiments.enabled(SPECS.MoveCartBIEventsTo130)
      ? this.siteStore.webBiLogger.report(
          updateClickedInSelectYourShippingDestinationPopupSfSite({
            ...biParams,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart.checkoutId,
          })
        )
      : this.siteStore.webBiLogger.report(updateClickedInSelectYourShippingDestinationPopupSf(biParams));
  }

  public errorInCheckoutSf(cart: ICart): void {
    const {destination} = cart;
    const subdivision = destination.subdivision ? ` - ${destination.subdivision}` : /* istanbul ignore next */ '';
    const destinationStr = `${destination.country}${subdivision}`;
    const params: errorInCheckoutSfParams = {
      ...this.baseBiParams(cart),
      field: 'destination',
      message: `cannot ship to destination ${destinationStr}`,
      stage: 'cart',
    };
    this.siteStore.webBiLogger.report(errorInCheckoutSf(params));
  }

  public cancelClickedInSelectYourShippingDestinationPopupSf(cart: ICart, cartType: CartType): void {
    const biParams = {
      ...this.baseBiParams(cart),
      cartType,
    };

    this.siteStore.experiments.enabled(SPECS.MoveCartBIEventsTo130)
      ? this.siteStore.webBiLogger.report(
          cancelClickedInSelectYourShippingDestinationPopupSfSite({
            ...biParams,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart?.checkoutId,
          })
        )
      : this.siteStore.webBiLogger.report(cancelClickedInSelectYourShippingDestinationPopupSf(biParams));
  }

  public cartClickOnContinueShoppingSf(cart: ICart, origin: ContinueShoppingBiOrigin): void {
    const biParams = {...this.baseBiParams(cart), origin};
    this.siteStore.experiments.enabled(SPECS.MoveCartBIEventsTo130)
      ? this.siteStore.platformBiLogger.clickOnContinueShoppingFromCart({
          ...biParams,
          catalogAppId: getCatalogAppIds(cart),
          checkoutId: cart?.checkoutId,
          numberOfAdditionalFees: getNumberOfAdditionalFees(cart),
          additionalFeesPrice: getAdditionalFeesPrice(cart),
          itemType: getItemTypes(cart),
        })
      : this.siteStore.webBiLogger.report(cartClickOnContinueShoppingSf(biParams));
  }

  public minimumOrderMessageIsShownInCart(cart: ICart, buttonEnabled: boolean): void {
    const biParams = {
      ...this.baseBiParams(cart),
      currency: cart.currencyFormat.code,
      minimumValueLong: Math.round(cart.minimumOrderAmount.convertedValue * 1e5),
      diffLong: Math.round(cart.minimumOrderAmount.convertedDiff * 1e5),
      buttonEnabled,
    };
    this.siteStore.experiments.enabled(SPECS.MoveCartBIEventsTo130)
      ? this.siteStore.platformBiLogger.cartMinimumOrderMessageIsShownInCart({
          ...biParams,
          checkoutId: cart?.checkoutId,
          catalogAppId: getCatalogAppIds(cart),
        })
      : this.siteStore.webBiLogger.report(minimumOrderMessageIsShownInCart(biParams));
  }

  public clickOnAddNoteToSellerSf(cart: ICart): void {
    this.siteStore.experiments.enabled(SPECS.MoveCartBIEventsTo130)
      ? this.siteStore.webBiLogger.report(
          clickOnAddNoteToSellerSfSite({
            ...this.baseBiParams(cart),
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart?.checkoutId,
          })
        )
      : this.siteStore.webBiLogger.report(
          clickOnAddNoteToSellerSf({
            ...this.baseBiParams(cart),
          })
        );
  }

  public clickOnApplyPromotionalCodeSf(cart: ICart): void {
    this.siteStore.webBiLogger.report(
      clickOnApplyPromotionalCodeSf({
        ...this.baseBiParams(cart),
      })
    );
  }

  public errorWhenApplyingACouponSf(cart: ICart, couponCode: string, errorCode: string): void {
    this.siteStore.webBiLogger.report(
      errorWhenApplyingACouponSf({
        ...this.baseBiParams(cart),
        couponCode,
        errorCode,
      })
    );
  }

  public updateBuyerNote = (cart: ICart, hasNote: boolean): void => {
    const biParams = {cartId: cart.cartId, origin: ORIGIN};
    if (this.siteStore.experiments.enabled(SPECS.MoveCartBIEventsTo130)) {
      const catalogAppId = getCatalogAppIds(cart);
      hasNote
        ? this.siteStore.webBiLogger.report(
            saveNoteToSellerSfSite({
              ...biParams,
              is_empty: false,
              catalogAppId,
              checkoutId: cart.checkoutId,
            })
          )
        : this.siteStore.webBiLogger.report(
            deleteNoteToSellerSfSite({
              ...biParams,
              catalogAppId,
              checkoutId: cart.checkoutId,
            })
          );
    } else {
      hasNote
        ? this.siteStore.webBiLogger.report(saveNoteToSellerSf({...biParams, is_empty: false, storeId: cart.storeId}))
        : this.siteStore.webBiLogger.report(deleteNoteToSellerSf({...biParams, storeId: cart.storeId}));
    }
  };

  public viewCartPageSf({
    cart,
    cartType,
    isEligibleForCheckoutInViewer,
    paymentMethods,
    numOfVisibleShippingOptions,
    shouldShowCoupon,
    shouldShowBuyerNote,
    shouldShowContinueShopping,
    shouldShowShipping,
    shouldShowTax,
    hasPickupOption,
  }: {
    cart: ICart;
    cartType: string;
    isEligibleForCheckoutInViewer: boolean;
    paymentMethods: PaymentMethod[];
    numOfVisibleShippingOptions: number;
    shouldShowCoupon: boolean;
    shouldShowBuyerNote: boolean;
    shouldShowContinueShopping: boolean;
    shouldShowShipping: boolean;
    shouldShowTax: boolean;
    hasPickupOption: boolean;
  }): void {
    const isShippingCalculated = cart?.shippingRuleInfo?.status === ShippingRuleStatus.OK;
    const biParams = {
      ...this.baseBiParams(cart),
      cartType,
      checkoutType: isEligibleForCheckoutInViewer ? 'viewer' : 'not in viewer',
      isContinueShopping: shouldShowContinueShopping,
      isDeliveryRates: shouldShowShipping,
      isPickupAvailable: hasPickupOption,
      isShippingCalculated,
      isTaxCosts: shouldShowTax,
      is_note: shouldShowBuyerNote,
      is_promo_code: shouldShowCoupon,
      is_with_ewallet_payment: paymentMethods.map((m) => m.type).includes(PaymentMethodType.eWallet),
      is_with_offline_payment: paymentMethods.map((m) => m.type).includes(PaymentMethodType.offline),
      itemsCount: cart?.items.reduce((count, item) => count + item.quantity, 0) || 0,
      lineItemsCount: cart?.items.length || 0,
      num_of_paymet_providers: paymentMethods.length,
      num_of_shipping: numOfVisibleShippingOptions,
      paymet_providers: paymentMethods.map((m) => m.name).join(','),
      shippingReason: isShippingCalculated ? '' : cart?.shippingRuleInfo?.status || 'unknown',
      viewMode: this.siteStore.viewMode,
      additionalFeesPrice: getAdditionalFeesPrice(cart),
      numberOfAdditionalFees: getNumberOfAdditionalFees(cart),
    };

    this.siteStore.experiments.enabled(SPECS.MoveCartBIEventsTo130)
      ? this.siteStore.webBiLogger.report(
          viewCartPageSfSite({
            ...biParams,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart?.checkoutId,
          })
        )
      : this.siteStore.webBiLogger.report(viewCartPageSf(biParams));
  }

  private static setDestinationBIData(cart: ICart, cartType: string) {
    const biData: SetDestinationBIData = {
      cartId: cart.cartId,
      cartType,
    };
    if (cart.destination) {
      biData.shippingDestinationCountry = cart.destination.country;
      biData.shippingDestinationState = cart.destination.subdivision;
    }
    return biData;
  }
}
